/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import facebook from "../../assets/images/icons/icon-facebook.svg";
import twitter from "../../assets/images/icons/icon-twitter.svg";
import whatsapp from "../../assets/images/icons/icon-whatsapp.png";
import email from "../../assets/images/icons/icon-email.svg";

/*custom components*/
import PageLoading from "../../components/PageLoading";
import SliderImage from "../../components/SliderImage";
import Text from "../../components/Text";
import Slider from "../../components/Slider";
import NotFounded from "../../components/NotFounded";
import Card from "../../components/Card";
import Button from "../../components/Button";
import Image from "../../components/Image";
import Modal from "../../components/Modal";
import Form from "../../components/Form";
import CategoryBlog from "../../components/CategoryBlog";
import { Helmet } from "react-helmet";
/*functions*/
import {
  getShopCart,
  getCookie,
  getFilePath,
  getRelatedProducts,
  loadPageV2,
  setGTMEvent,
  getCurrentStore,
  getTagDefinitions,
  getLoginInfo,
  getOptions,
  formatPathName,
  getWishList,
  addWishlist,
  toFixed,
  getStoresInfo,
  findValueById,
  validCombo,
  getImgagesExtension,
  getLanguage,
  manageCart,
  getImagePath,
  validateNumber,
  buildProductsSlider,
  selectOption,
  shareProduct,
  find,
  addShopCart,
  getDefValues,
  getCurrency,
  callApi,
  showError,
  showMessage,
} from "../../common/functions";
import { toast, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";

const foldername = process.env.REACT_APP_FOLDER_LAYOUT;
var windowWidth = window.innerWidth;
class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: null,
      isLoading: true,
      noProduct: true,
      attrib_custom_landing: false,
      modal1: false,
      isLoading2: false,
      isLoadingSlider: false,
      hideWishlistOption: false,
      mainClass: "page-wrapper",
      optionsselected: [],
      cstyle: {},
      tags: [],
      MetaTags: {},
      breadcrumbs: [],
      prodcustominfoArr: [],
      orgName: process.env.REACT_APP_ORG_NAME,
      btnaddbutton: getDefValues().btnaddbutton.toUpperCase(),
      btnaddListbutton: getDefValues().addEventListButton.toUpperCase(),

      currency: getCurrency(),
      proddecriptionlabel: getDefValues().proddecriptionlabel.toUpperCase(),
      prodaditionalinfo: getDefValues().prodaditionalinfo.toUpperCase(),
      prodcustominfo: "",
      prodShare: getDefValues().prodShare.toUpperCase(),
      showproddesc: true,
      showcustominfo: true,
      redirectProductDetails: false,
      selectedProductID: 0,
      selectedPath: "",
      selectedCategory: "",
      showprodinfo: true,
      tempimages: [
        { image: "/catalog/no_sku.png", imagealt: "Loading" },
        { image: "/catalog/no_sku.png", imagealt: "Loading" },
      ],
      relatedProductsLabel: { text: getDefValues().relatedProductsLabel },
      relatedProductsLabelConfig: {
        fontsize: "25px",
        fontweight: "600",
        color: "#696158",
        margintop: "10px",
        textdecoration: "nonw",
      },
      relatedProducts: [],
      relatedProductsConfig: {},
      product: {
        images: [],
        imagesZoom: [],
        features: [],
        options: [],
        quantities: [],
        quantitySelected: 1,
        maxQuantity: 1,
        aditionalInfo: "",
        description: "",
        sku: "",
        discount: 0,
        price: 0,
        skusInCombo: [],
        skusInDiscount: [],
        productAttributes: [],
        skusInDisctount: [],
      },
      skusinDiscount: [],
      skusinCombo: [],
      optionsArr: [],
      isValid: false,
      selectedImage: "",
      productTitle: "",
      selectedSku: "",
      selectedPrice: 0,
      selectedSKUS: [],
      wishButton: "btn-outline-",
      btncolor: "primary",
      quantityProduct: [],
      cardData: [],
      cardConfig: {},
      productNotFounded: false,
      messages: [],
      sliderConfigZoom: {
        imageCarrousel: true,
        arrows: true,
        dots: true,
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 200,
        zoomable: true,
      },
      sliderConfig: {
        imageCarrousel: true,
        arrows: true,
        dots: true,
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 200,
      },

      prodDefConfig: {
        dots: false,
        arrows: true,
        slidesToShow: this.props.windowWidth < 799 ? 2 : 4,
        slidesToScroll: 2,
        autoplay: true,
        cssEase: "linear",
        speed: 500,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        divWidth: "card col-md-12 col-lg-12 col-xl-12",
      },
    };
  }
  /*loading data*/
  componentDidMount = async () => {
    if (this.props.match.params.productname.indexOf("-") >= 0) {
      getShopCart("VIEWPRODUCT");
      /*
			if ((window.location.href).indexOf("suggestedcolor_")>=0 ){
				let _suggestedcolor = (window.location.href).split("suggestedcolor_")
				let suggestedcolor = _suggestedcolor[1].replace("#", "");
				console.log("suggestedcolor",suggestedcolor)
				this.setState({...this.state,   suggestedcolor:suggestedcolor });		
			}
			*/

      this.props.hideCart();
      var productPath = window.location.pathname.replace(
        this.props.match.params.productname,
        ""
      );
      productPath = productPath.substr(1, productPath.length);
      productPath = productPath.substr(0, productPath.length - 1);
      this.setState({
        ...this.state,
        isLoading: true,
        noProduct: false,
        productPath: productPath,
      });
      var optionsArr = await getOptions();
      this.setState({ optionsArr: optionsArr });

      var aditionalInfo = this.props.location.search.replace("#", "");
      var eventId = this.props.eventId;
      var action = this.props.action;

      //console.log(eventId, action)
      if (action !== "") {
        this.setState({ redirectToEvent: true });

        try {
          let data = aditionalInfo.replace("?", "").split("&");
          const prodDefConfig = this.state.prodDefConfig;
          for (let d in data) {
            if (data[d].startsWith("suggestedcolor")) {
              let _suggestedcolor = data[d].split("suggestedcolor_");
              this.setState({
                ...this.state,
                suggestedcolor: _suggestedcolor[1],
              });
            } else {
              /*
	 						var _data = data[d].split("=")
	 						prodDefConfig[_data[0]] = _data[1]	 						
	 						this.setState({[_data[0]]:_data[1]});		
	 						*/
            }
          }
          prodDefConfig["action"] = action;
          prodDefConfig["id"] = eventId;

          let respE = await callApi("frontend", "getCustomerEventById", {
            languageid: getLanguage(),
            eventid: eventId,
          });
          prodDefConfig["eventskus"] = respE.PRODUCTS;
          this.setState({
            ...this.state,
            btncolor: "secondary",
            eventskus: respE.PRODUCTS,
            id: eventId,
            action: action,
            hideWishlistOption: action === "addEventList",
          });

          /*
	 				if (this.state.action == "addEventCart" || this.state.action == "addEventList" ){
	 					let respE = await callApi ( "frontend","getCustomerEventById", {languageid: getLanguage(), eventid:this.state.id } )
						prodDefConfig["eventskus"] = respE.PRODUCTS
						this.setState({...this.state, eventskus:respE.PRODUCTS	 });		
	 				}
	 				*/
        } catch (err) {
          console.log("ERR getting actions", err);
        }
      }

      //CUSTOM INFO
      var respAttr = await callApi("customer", "getAttributes");
      var prodcustominfoArr = [];
      for (let k in respAttr.rows) {
        let key = respAttr.rows[k];
        if (key.ATTRIBUTECATEGORY === "landings") {
          prodcustominfoArr.push({
            value: key.VALUE,
            label: key.NAME,
            show: true,
          });
          this.setState({ prodcustominfo: key.NAME });
          var respT = await callApi(
            "layaoutManager",
            "getLayoutTemplateByName",
            { layoutFile: key.VALUE, foldername: foldername + "private/" }
          );
          let _pageData = await loadPageV2(
            respT.template,
            null,
            null,
            null,
            null,
            null,
            windowWidth
          );
          this.renderData(key.VALUE, _pageData);
        }
      }
      this.setState({ prodcustominfoArr: prodcustominfoArr });

      //SET PRODUCT
      await this.setProduct();
      var f = new Date();
      var year = f.getFullYear();
      year = year + 4;
      var url =
        process.env.REACT_APP_URL_FRONT_CONFIG + window.location.pathname;

      if (this.state.product.productid) {
        var login = getLoginInfo();
        let storesInfo = await getStoresInfo();
        let pos = find(
          storesInfo.stores,
          storesInfo.selectedStoreId,
          "storeId"
        );
        var storeName = storesInfo.stores[pos].storeName;
        var objProduct = {
          current_view: "product",
          current_list:
            "product: " +
            this.state.product.productid +
            " - " +
            this.state.product.title,
          current_currency: "BOB",
          userId: login.userId ? login.userId : "0",

          gdpr_marketing_status: "accepted",
          gdpr_statistics_status: "accepted",

          fb_pixel_track_name: "ViewContent",
          fb_pixel_track_info: {
            value: this.state.product.price,
            currency: "BOB",
            content_ids: [this.state.product.productid],
            content_name: this.state.product.title,
            content_type: "product",
          },

          store_name: process.env.REACT_APP_ORG_NAME + "-" + storeName,
          googleDynamicRemarketing: {
            ecomm_prodid: this.state.product.productid + "",
            dynx_itemid: this.state.product.productid,
            dynx_itemid2: this.state.product.productid,
            ecomm_totalvalue: this.state.product.price,
            ecomm_pagetype: "product",
            ecomm_category: this.state.product.category,
          },

          //gtm: {uniqueEventId: 1666, start: 1649342601964},
          criteoParams: {
            PageType: "ProductPage",
            ProductID: this.state.product.productid,
            email: "",
          },

          ecommerce: {
            detail: {
              actionField: {
                list:
                  "product: " +
                  this.state.product.productid +
                  " - " +
                  this.state.product.title,
              },
              products: [
                [
                  {
                    id: this.state.product.productid,
                    name: this.state.product.title,
                    price: this.state.product.price,
                    brand: "CasaIdeas",
                    category: this.state.product.category,
                    quantity: this.state.product.quantitySelected,
                  },
                ],
              ],
            },
          },
          productDetails: {
            product: {
              price_final: this.state.product.price,
              image_url: this.state.product.images[0]
                ? this.state.product.images[0].image
                : "",
              url: url,
              priceValidUntil: year + "-12-31",
              meta_description: this.state.product.description,
              name: this.state.product.title,
              manufacturer: null,
              category: this.state.product.category,
              sku: this.state.product.sku,
              mpn: "",
              store: getCurrentStore(),
              product_id: this.state.product.productid,
              availability: "https://schema.org/InStock",
              sizes_domodi: "",
              ratingValue: 0,
              reviewCount: 0,
            },
            reviews: [],
          },
        };
        setGTMEvent("productDetails", objProduct);
      }

      //TagManager.dataLayer(tagManagerArgs)
    } else {
      this.setState({ ...this.state, noProduct: true, isLoading: false });
      //console.log("no p")
    }
  };
  setProduct = async () => {
    try {
      var _resp = await getTagDefinitions();
      var tags = _resp.tags;
      this.setState({ ...this.state, tags: tags });
      var _productid = this.props.match.params.productname.split("-");
      var productid = _productid[_productid.length - 1];

      let _path = productid.toLowerCase().split("_c_");
      var mandatorySku = "";
      if (_path.length === 2) {
        mandatorySku = _path[1];
        productid = _path[0];
      }

      var respOpt = await callApi("customer", "getOptions");
      var respAttr = await callApi("customer", "getAttributes");
      var store = await getStoresInfo();
      var isValid = false;
      var productTitle = "";
      var product = {};
      var selectedSKUS = [];
      var suggestedQty = 0;
      var estyle = {};
      var oldresp = await callApi("customer", "getProductById", {
        productid: productid,
        storeid: store.selectedCatalogId,
      });

      var resp2 = await callApi("customer", "getProductByIdElastic", {
        id: store.selectedCatalogId + "0" + productid,
      });

      var resp = { success: false };

      if (resp2.success && oldresp.success) {
        if (resp2.rows.length === 1) {
          let p = resp2.rows[0];
          let PRODUCT = {
            AGE: p.age,
            CANONICALPATHID: parseInt(p.canonicalpath_id),
            CATEGORIES: p.categories,
            CATEGORY: p.category,
            DATEAVAILABLE: p.dateadded,

            ATTRIBUTES: oldresp.PRODUCT.ATTRIBUTES,
            DECIMALSTEP: oldresp.PRODUCT.DECIMALSTEP,
            DOWNLOADS: oldresp.PRODUCT.DOWNLOADS,
            IMAGES: oldresp.PRODUCT.IMAGES,
            PRODUCTDESCS: oldresp.PRODUCT.PRODUCTDESCS,

            GENERICSKU: p.genericsku,
            MINIMUMQUANTITY: 1,
            OUTOFSTOCKSTATUSID: 20,
            PRICE: p.price,
            PRODUCTID: parseInt(p.productid),
            PRODUCTMODEL: p.packname ? p.packname : p.genericsku,
            SORTORDER: p.sortorder,
            STATUSID: p.statusid,

            productquantities: p.quantities,
            TAXCLASS_ID: -1,
            SPECIALS: [],
            PRODUCTLOCATION: null,
            EAN: null,
            ISBN: null,
            JAN: null,
            LENGTHCLASSID: null,
            MANUFACTURERID: null,
            QUANTITY: 0,
            DISCOUNTS: [],
            RELATEDPRODUCTS: [],
            REQUIRESSHIPPING: 15,
            REWARDPOINTS: [],
            MPN: null,
            DIMENSIONH: null,
            DIMENSIONL: null,
            DIMENSIONW: null,
            POINTS: null,
            STORES: [],
            SUBTRACTSTOCKID: 15,
            UPC: null,
            WEIGHT: null,
            WEIGHTCLASSID: null,
            FILTERS: [],
          };
          if (p["packname"]) {
            PRODUCT["PACKNAME"] = p["packname"];
          }
          resp["success"] = true;
          resp["PRODUCT"] = PRODUCT;
        }

        var eventCookieId = getCookie(
          process.env.REACT_APP_ORG_NAME + "_eventId"
        );

        if (eventCookieId && mandatorySku !== "") {
          //console.log("si")
          if (
            eventCookieId != "0" &&
            eventCookieId != "null" &&
            eventCookieId !== ""
          ) {
            //console.log("si2")
            let respE = await callApi("frontend", "getCustomerEventById", {
              languageid: getLanguage(),
              eventid: eventCookieId,
            });
            if (respE.success) {
              let prodTagPos = find(respE.PRODUCTS, mandatorySku, "productsku");

              suggestedQty =
                respE.PRODUCTS[prodTagPos].quantity -
                respE.PRODUCTS[prodTagPos].quantity_assigned;

              //console.log(prodTagPos)
            }
          }
        }
      }
      resp = oldresp;
      //console.log("old resp",oldresp)
      //console.log("FIXED resp",resp)

      var notValid = true;
      //var categoryid;

      if (!resp.success) {
        this.setState({
          isLoading: true,
          productNotFounded: true,
          messages: [getDefValues().notFoundsubTitleLabel1],
        });

        var catId = 28;
        var catIds = getCookie(
          process.env.REACT_APP_ORG_NAME + "_MasterCategoryIds"
        );
        if (catIds) {
          let catIds = JSON.parse(
            getCookie(process.env.REACT_APP_ORG_NAME + "_MasterCategoryIds")
          );
          var pos = Math.floor(Math.random() * catIds.length);
          catId = catIds[pos];
          //console.log(catIds, pos,catId)
        }

        var data = {
          languageid: getLanguage(),
          categoryid: catId,
          storeid: store.selectedCatalogId,
        };

        //console.log("categories0", data)
        resp = await callApi("customer", "getFilterProductsDB", data);

        var products = [];
        for (let k in resp.rows) {
          if (products.length <= 10 && resp.rows[k].productid != productid) {
            products.push(resp.rows[k]);
          }
        }
        let respCat = await buildProductsSlider(
          products,
          null,
          this.manageCart,
          this.state.prodDefConfig,
          "products",
          this.state.productPath
        );
        this.setState({
          ...this.state,
          cardConfig: respCat.cardConfig,
          cardData: respCat.cardProducts,
        });
        this.setState({
          isLoading: false,
          productNotFounded: true,
          messages: [
            getDefValues().notFoundsubTitleLabel2,
            getDefValues().notFoundsubTitleLabel3,
          ],
        });
      } else {
        //console.log("resp.PRODUCT.CATEGORIES", resp.PRODUCT.CATEGORIES)
        var categories = resp.PRODUCT.CATEGORIES;
        var cats = window.location.pathname.split("\\/");
        cats.splice(0, 1);
        var catsIds = [];
        resp.PRODUCT.CATEGORIES.map(function (key) {
          //catsIds.push ( {id: key.id ,label: key.categoryname , path: formatPathName(key.categoryname)  } )
          catsIds.push({
            id: key.id,
            label: key.category,
            path: formatPathName(key.category),
          });
        });
        var breadcrumbs = [];
        var link = "";
        for (let c = 0; c < cats.length - 1; c++) {
          let cat = cats[c];
          link = link + "/" + cat;
          let id = findValueById(catsIds, cat, "path", "id");
          let label = findValueById(catsIds, cat, "path", "label");
          if (label === "NOT FOUND") {
            label = cat.split("-").join(" ");
            label = label.toLowerCase();
          }
          breadcrumbs.push({
            label: label,
            id: id === "NOT FOUND" ? 0 : id,
            link: id === "NOT FOUND" ? "#" : link + "/" + id,
          });
        }

        //categoryid = resp.PRODUCT.CATEGORIES[0].id;

        var productquantities = [];
        if (resp.PRODUCT.PACKNAME) {
          productquantities = resp.PRODUCT.productquantities;
          mandatorySku = "";
          if (productquantities.length < 1) {
            notValid = false;
          } else {
            notValid = true;
            productquantities.map(function (key) {
              if (key.quantity <= 0) {
                notValid = false;
              }
            });
          }
        } else {
          if (mandatorySku === "") {
            productquantities = resp.PRODUCT.productquantities;
          } else {
            resp.PRODUCT.productquantities.map(function (key) {
              if (key.sku === mandatorySku) {
                productquantities.push(key);
              }
            });
          }
          if (productquantities.length < 1) {
            notValid = false;
          } else {
            notValid = false;
            productquantities.map(function (key) {
              if (key.quantity > 0) {
                notValid = true;
              }
            });
          }
        }
        this.setState({ ...this.state, breadcrumbs: breadcrumbs });

        if (!notValid) {
          //console.log("categories1", categories)
          let products = await getRelatedProducts(productid, categories);
          let respCat = await buildProductsSlider(
            products,
            null,
            this.manageCart,
            this.state.prodDefConfig,
            "products"
          );
          this.setState({
            ...this.state,
            cardConfig: respCat.cardConfig,
            cardData: respCat.cardProducts,
          });
          this.setState({
            isLoading: false,
            productNotFounded: true,
            messages: [
              getDefValues().notFoundsubTitleLabel2,
              getDefValues().notFoundsubTitleLabel3,
            ],
          });
        } else {
          var wishlist = await getWishList();
          var val = validateNumber(productid);
          var wishButton = "btn-outline-";
          if (val) {
            wishButton =
              wishlist.indexOf(parseInt(productid)) >= 0
                ? "btn-primary"
                : "btn-outline-";
          }

          var descriptions;
          resp.PRODUCT.PRODUCTDESCS.map(function (key) {
            if (key.LANGUAGEID == localStorage.getItem("def_language")) {
              product["title"] = key.NAME;
              product["description"] = key.DESCRIPTION;
              productTitle = key.METATAGTITLE ? key.METATAGTITLE : key.NAME;
              let _desc = key.DESCRIPTION.split("\n");
              let _descriptions;
              if (_desc.length > 0) {
                _descriptions = _desc[0];
              }
              descriptions = key.METATAGDESCRIPTION
                ? key.METATAGDESCRIPTION
                : _descriptions;
              product["descriptions"] = _desc;
            }
          });

          let metaImage =
            resp.PRODUCT.productquantities[0].imagespath +
            resp.PRODUCT.productquantities[0].sku +
            "_1" +
            getImgagesExtension();
          let metaDesc = descriptions ? descriptions : productTitle;

          var MetaTags = {
            title: productTitle,
            path: window.location.href,
            description: metaDesc,
            names: [
              { name: "description", content: metaDesc },
              { name: "keywords", content: productTitle.replace(" ", ",") },
              { name: "robots", content: "INDEX,FOLLOW" },
            ],
            properties: [
              { id: "og-title", property: "og-title", content: productTitle },
              { id: "og-title", property: "og:type", content: "product" },
              {
                id: "og-image",
                property: "og:image",
                content:
                  getImagePath(metaImage) +
                  "?optimize=low&bg-color=255,255,255&fit=bounds&height=265&width=265&canvas=265:265",
              },
              {
                id: "og-description",
                property: "og:description",
                content: metaDesc,
              },
              {
                id: "og-url",
                property: "og:url",
                content: window.location.href,
              },
              {
                id: "og-price",
                property: "og:price",
                content: resp.PRODUCT.PRICE + "",
              },
              {
                id: "product-price:amount",
                property: "product:price:amount",
                content: resp.PRODUCT.PRICE + "",
              },
              {
                id: "product-availability",
                property: "product:availability",
                content: "instock",
              },
              {
                id: "product-condition",
                property: "product:condition",
                content: process.env.REACT_APP_ORG_NAME,
              },
              {
                id: "product-availability",
                property: "product:availability",
                content: "new",
              },
              {
                id: "product-price-currency",
                property: "product:price:currency",
                content: process.env.REACT_APP_DEF_CURRENCYISO
                  ? process.env.REACT_APP_DEF_CURRENCYISO
                  : "BOB",
              },
            ],
          };

          //categoryid = resp.PRODUCT.CATEGORIES[0].id
          //console.log("MetaTags", MetaTags)
          var quantities = [];
          if (resp.PRODUCT.PACKNAME) {
            let _sku = productquantities[0];
            let _skus = [];
            var _quantity = 99999999;
            productquantities.map(function (q) {
              if (q.quantity > 0) {
                if (q.quantity < _quantity) {
                  _quantity = q.quantity;
                }
              }
              let pos = find(_skus, q.sku, "productsku");
              if (pos < 0) {
                _skus.push({ productsku: q.sku, price: q.price });
              }
            });
            let imgPath = resp.PRODUCT.IMAGES[0].PATH;
            var imagesquantity = resp.PRODUCT.IMAGES[0].SORTORDER;
            var imagepath = null;
            var imagespath = null;

            if (imgPath.indexOf(getImgagesExtension()) >= 0) {
              imagepath = imgPath;
              imagesquantity = 1;
            } else {
              imagespath = imgPath;
            }
            var fixedSku = {
              imagesquantity: imagesquantity,
              imagespath: imagespath,
              imagepath: imagepath,
              isrequiredid: _sku.isrequiredid,
              tagid: _sku.tagid,
              optionid: 3,
              optionmaster: "",
              optionvalue: "",
              optioncustomer: "PACK",
              newpriced: null,
              newpriced2: null,
              newpricec1: null,
              newpricec2: null,
              languageid: _sku.languageid,
              price: resp.PRODUCT.PRICE,
              sku: resp.PRODUCT.PACKNAME,
              skus: _skus,
              specificname: product["title"],
              quantity: _quantity,
            };
            quantities.push(fixedSku);
            if (_skus.length > 0) {
              selectedSKUS = _skus;
            }
            //console.log(fixedSku)
          } else {
            quantities = productquantities;
          }

          //var quantities =[]
          var features = [];
          var sku = "";
          var images = [];
          var imagesZoom = [];
          var selOptions;
          for (let opt in respOpt.rows) {
            let key = respOpt.rows[opt];
            let optionLabel = key.optionname;
            let optionid = key.optionid;
            let optionvalues = [];
            let haveOption = false;
            //let selectedOption= null
            for (let opt in quantities) {
              if (key.optionid == quantities[opt].optionid) {
                haveOption = true;
                let pos = find(
                  optionvalues,
                  quantities[opt].optionvalue,
                  "value"
                );
                if (pos < 0) {
                  optionvalues.push({
                    value: quantities[opt].optionvalue,
                    label: quantities[opt].optioncustomer,
                    image: quantities[opt].image
                      ? quantities[opt].image.trim()
                      : "",
                    imageOption: quantities[opt].imagepath
                      ? quantities[opt].imagepath
                      : quantities[opt].imagespath +
                        quantities[opt].sku +
                        "_1" +
                        getImgagesExtension(),
                    style: { cursor: "pointer", opacity: "1" },
                    enable: true,
                  });
                }
                sku = !sku ? quantities[opt].sku : sku;
              }
              if (quantities[opt].imagepath) {
                let pos = find(
                  images,
                  getImagePath(quantities[opt].imagepath),
                  "image"
                );
                if (pos < 0 && images.length < 4) {
                  images.push({
                    image: getImagePath(quantities[opt].imagepath),
                    imagealt: quantities[opt].optionvalue,
                  });
                }
              }
            }
            if (haveOption) {
              if (
                this.state.suggestedcolor &&
                optionLabel.toUpperCase() === "COLOR"
              ) {
                features.push({
                  optionid,
                  optionLabel,
                  optionvalues,
                  selectedOption:
                    optionvalues.length === 1
                      ? optionvalues[0].value
                      : this.state.suggestedcolor,
                  oneOption: optionvalues.length === 1 ? true : false,
                });
                this.setState({ ...this.state, suggestedoptionid: optionid });
              } else {
                features.push({
                  optionid,
                  optionLabel,
                  optionvalues,
                  selectedOption:
                    optionvalues.length === 1 ? optionvalues[0].value : null,
                  oneOption: optionvalues.length === 1 ? true : false,
                });
              }
            }
          }

          //console.log("features",features)
          var discount = 0;
          //var combo=""
          var tag = resp.PRODUCT.AGE < 6 ? "" : ""; //	resp.PRODUCT.AGE <6 ?"NEW" :"";
          var allSkus = [];
          var skusinDiscount = [];
          var skusinCombo = [];
          var firstTime = true;
          var firstTimeP = true;
          var customTag;
          var cstyle = {};
          for (let o in quantities) {
            var key = quantities[o];
            if (key.tagid && !customTag) {
              let pos = find(tags, key.tagid + "", "id");
              if (pos >= 0) {
                if (tags[pos].storeId === "") {
                  customTag = {
                    label: tags[pos].label,
                    sku: key.sku,
                    style: tags[pos].style,
                  };
                } else {
                  if (tags[pos].storeId == getCurrentStore()) {
                    customTag = {
                      label: tags[pos].label,
                      sku: key.sku,
                      style: tags[pos].style,
                    };
                  }
                }
                cstyle = {
                  background: customTag.style.background
                    ? customTag.style.background
                    : "#FFF",
                  color: customTag.style.color ? customTag.style.color : "#000",
                  fontSize: customTag.style["font-size"]
                    ? parseInt(customTag.style["font-size"]) + 2 + "px"
                    : "22px",
                  fontWeight: customTag.style["font-weight"]
                    ? customTag.style["font-weight"]
                    : "normal",
                  textDecoration: customTag.style["text-decoration"]
                    ? customTag.style["text-decoration"]
                    : "none",
                  top: "4px",
                  opacity: "1",
                };
                if (customTag.style.position) {
                  if (customTag.style.position !== "") {
                    cstyle["top"] = customTag.style.position + "px";
                  }
                }

                if (customTag.style.positionb) {
                  if (customTag.style.positionb !== "") {
                    cstyle["top"] = "initial";
                    cstyle["bottom"] =
                      parseInt(customTag.style.positionb) + 74 + "px";
                  }
                }

                if (customTag.style.align) {
                  if (customTag.style.align === "left") {
                    cstyle["left"] = "1px";
                  } else {
                    cstyle["right"] = "1px";
                  }
                } else {
                  cstyle["right"] = "1px";
                }
                if (customTag.style.opacity) {
                  if (customTag.style.opacity === "0.75") {
                    cstyle["opacity"] = "0.25";
                  } else if (customTag.style.opacity === "0.50") {
                    cstyle["opacity"] = "0.50";
                  } else if (customTag.style.opacity === "0.25") {
                    cstyle["opacity"] = "0.75";
                  } else {
                    cstyle["opacity"] = "1";
                  }
                }
                if (customTag.style.imagepath) {
                  cstyle["paddingLeft"] = "0";
                  cstyle["paddingRight"] = "0";
                  cstyle["imagepath"] = true;
                }
              }
            }

            //quantities.push(key)
            if (firstTimeP) {
              product["price"] = parseFloat(key.price);
              firstTimeP = false;
            }
            if (key.newpriced) {
              if (firstTime) {
                product["newprice"] = parseFloat(key.newpriced);
                discount = toFixed(parseFloat(key.newpriced2));
                firstTime = false;
              }
              discount = toFixed(parseFloat(key.newpriced2));
              tag =
                "-" + parseInt(100 * toFixed(parseFloat(key.newpriced2))) + "%";
              if (skusinDiscount.indexOf(key.sku) < 0) {
                skusinDiscount.push(key.sku);
              }
            }
            if (key.newpricec1) {
              product["newpriceC"] = parseFloat(key.newpricec1);
              product["newpriceQ"] = parseFloat(key.newpricec2);
              tag =
                parseInt(key.newpricec1) +
                "x" +
                getCurrency() +
                "" +
                toFixed(
                  parseFloat(key.newpricec1) * parseFloat(key.newpricec2)
                );
              if (skusinCombo.indexOf(key.sku) < 0) {
                skusinCombo.push(key.sku);
              }
            }
            if (allSkus.indexOf(key.sku) < 0) {
              allSkus.push(key.sku);
            }
            if (!selOptions && key.quantity > 0) {
              selOptions = key;
            }
          }

          var selSku = selOptions.sku;
          var selImagepath = selOptions.imagepath;
          var selImagesquantity = selOptions.imagesquantity;
          var selImagespath = selOptions.imagespath;
          var selSpecificname = selOptions.specificname;

          if (selImagepath) {
            images.push({
              image: getImagePath(selImagepath),
              version: "both",
              imagealt: selSpecificname,
              onclick: this.toggle(1),
            });
            imagesZoom.push({
              image: getImagePath(selImagepath),
              version: "both",
              imagealt: selSpecificname,
              width: "529px",
              height: "529px",
              scale: 2,
            });
          } else {
            for (let i = 1; i <= selImagesquantity; i++) {
              if (images.length < 4) {
                images.push({
                  image: getImagePath(
                    selImagespath + selSku + "_" + i + getImgagesExtension()
                  ),
                  imagealt: selSpecificname,
                  version: "both",
                  onclick: this.toggle(1),
                });
              }
              imagesZoom.push({
                image: getImagePath(
                  selImagespath + selSku + "_" + i + getImgagesExtension()
                ),
                imagealt: selSpecificname,
                version: "both",
                width: "605px",
                height: "605px",
                scale: 2,
              });
            }
          }

          var skusInCombo = [];
          if (skusinCombo.length !== allSkus.length) {
            skusinCombo.map(function (sku) {
              var options = [];
              quantities.map(function (op) {
                if (op.sku == sku) {
                  if (options.indexOf(op.optioncustomer) < 0) {
                    options.push(op.optioncustomer);
                  }
                }
              });
              skusInCombo.push({ sku: sku + "", options: options.toString() });
            });
          }
          var skusInDiscount = [];
          if (skusinDiscount.length !== allSkus.length) {
            skusinDiscount.map(function (sku) {
              var options = [];
              quantities.map(function (op) {
                if (op.sku == sku) {
                  if (options.indexOf(op.optioncustomer) < 0) {
                    options.push(op.optioncustomer);
                  }
                }
              });
              skusInDiscount.push({
                sku: sku + "",
                options: options.toString(),
              });
            });
          }
          if (quantities.length === 1) {
            features[0]["selectedOption"] = quantities[0].optionvalue;
          }
          if (features.length === 0) {
            isValid = true;
          }

          product["skusInCombo"] = skusInCombo;
          product["skusInDiscount"] = skusInDiscount;
          product["productid"] = resp.PRODUCT.PRODUCTID;
          product["dateavailable"] = resp.PRODUCT.DATEAVAILABLE;
          product["category"] = resp.PRODUCT.CATEGORY
            ? formatPathName(resp.PRODUCT.CATEGORY)
            : "-";
          product["categoryid"] = resp.PRODUCT.CANONICALPATHID;
          product["decimalstep"] = resp.PRODUCT.DECIMALSTEP;
          product["decimalstep"] = resp.PRODUCT.DECIMALSTEP;
          product["features"] = features;
          product["quantities"] = quantities;
          product["quantitySelected"] = resp.PRODUCT.MINIMUMQUANTITY;
          product["minQuantity"] = resp.PRODUCT.MINIMUMQUANTITY;
          product["discount"] = discount;
          product["tag"] = tag;
          product["images"] = images;
          product["originalimages"] = images;
          product["imagesZoom"] = imagesZoom;
          product["originalimagesZoom"] = imagesZoom;
          product["originaldiscount"] = discount;
          product["sku"] = sku;

          if (resp.PRODUCT.PACKNAME) {
            product["packname"] = resp.PRODUCT.PACKNAME;
          }

          var maxQuantity = 1;
          quantities.map(function (key) {
            if (maxQuantity < key.quantity) {
              maxQuantity = key.quantity;
            }
          });

          var attributes = respAttr.rows;
          var productAttributes = [];
          resp.PRODUCT.ATTRIBUTES.map(function (key) {
            let attr = findValueById(attributes, key.ID, "ATTRIBUTEID", "NAME");
            let type = findValueById(
              attributes,
              key.ID,
              "ATTRIBUTEID",
              "ATTRIBUTECATEGORY"
            );

            if (attr && key.ATRIBUTEVALUE) {
              let _atributevalues = key.ATRIBUTEVALUE.replaceAll("/", "***")
                .replaceAll("\n", "***")
                .replaceAll("<BR>", "***");
              let atributevalues = _atributevalues.split("***");
              let pos = find(productAttributes, attr, "label");

              if (pos < 0) {
                productAttributes.push({
                  label: attr,
                  value: key.ATRIBUTEVALUE,
                  atributevalues: atributevalues,
                  type: type,
                });
              } else {
                let attr = productAttributes[pos];

                attr["value"] = attr["value"] + "," + key.ATRIBUTEVALUE;
                attr["atributevalues"] =
                  attr["atributevalues"].concat(atributevalues);
              }
            }
          });
          //console.log(productAttributes)
          product["productAttributes"] = productAttributes;
          product["maxQuantity"] = maxQuantity;
          var selectedImage = images[0].image;
          /*********BLOOMREACH*********/
          let url = window.location.href;
          let urlObj = new URL(url);
          let pathParts = urlObj.pathname.split("/");
          pathParts.splice(pathParts.length - 1, 1);

          var objBloomreach_ViewItem = {
            item_name: product.title,
            item_id: product.packname || sku,
            item_price: parseFloat(product.price),
            origen: "web",
          };

          if (pathParts[1]) {
            objBloomreach_ViewItem["cat_espacios_level_1"] = pathParts[1];
          }
          if (pathParts[2]) {
            objBloomreach_ViewItem["cat_espacios_level_2"] = pathParts[2];
          }
          if (pathParts[3]) {
            objBloomreach_ViewItem["cat_espacios_level_3"] = pathParts[3];
          }
          //console.log(objBloomreach_ViewItem);
          window.exponea.track("view_item", objBloomreach_ViewItem);
          /*********BLOOMREACH*********/
          this.setState({
            isLoading: false,
            customTag: customTag,
            cstyle: cstyle,
            wishButton: wishButton,
            MetaTags: MetaTags,
            isValid: isValid,
            product: product,
            skusinDiscount: skusinDiscount,
            skusinCombo: skusinCombo,
            productTitle: productTitle,
            selectedImage: selectedImage,
            selectedSku: sku,
            selectedPrice: parseFloat(product.price),
            selectedSKUS: selectedSKUS,
            suggestedQty: suggestedQty,
            quantityProduct: quantities,
          });

          for (let f in features) {
            if (features[f].oneOption) {
              resp = await selectOption(
                features[f].optionid,
                features[f].selectedOption,
                this.state.product,
                this.state.selectedPrice,
                this.state.selectedSku,
                this.state.quantityProduct,
                this.state.selectedImage,
                this.state.optionsArr
              );
              if (resp.success) {
                if (resp.isValid) {
                  let product = resp.product;
                  if (resp.changeImage) {
                    var newImagesZoom = [];
                    var newImages = [];

                    for (let i = 0; i < product.images.length; i++) {
                      let key = product.images[i];
                      if (i < 4) {
                        newImages.push({
                          image: key.image,
                          version: "both",
                          imagealt: key.imagealt,
                          onclick: this.toggle(1),
                        });
                      }
                      newImagesZoom.push({
                        image: key.image,
                        version: "both",
                        imagealt: key.imagealt,
                        width: "529px",
                        height: "529px",
                        scale: 2,
                      });
                    }
                    product["imagesZoom"] = newImagesZoom;
                    product["images"] = newImages;
                    this.setState({ product: product });
                    this.setState({ isLoadingSlider: true });

                    setTimeout(
                      function () {
                        //this.setState({isLoadingSlider:false, sliderConfig: resp.sliderConfig });
                        this.setState({
                          product: product,
                          isLoadingSlider: false,
                          //		sliderConfig: resp.sliderConfig,
                          isValid: resp.isValid,
                          selectedSku: product.sku,
                          selectedImage: product.image,
                          selectedPrice: product.price,
                          optionsselected: product.optionsselected,
                        });
                      }.bind(this),
                      10
                    );
                  }

                  //this.setState({product:product,isValid:resp.isValid, selectedSku: resp.selectedSku, selectedImage:resp.selectedImage, selectedPrice:resp.selectedPrice });
                }
              }
            }
          }

          if (this.state.suggestedoptionid && this.state.suggestedcolor) {
            var _event = {
              target: {
                name:
                  this.state.suggestedoptionid +
                  "__" +
                  this.state.suggestedcolor.replace("%20", " "),
              },
            };

            await this.selectOption(_event);
          }
          var mailValue =
            "mailto:?subject=" +
            this.state.productTitle.replaceAll(" ", "%20") +
            "&body=" +
            (process.env.REACT_APP_URL_FRONT_CONFIG +
              window.location.pathname
                .replaceAll(":", "%3A")
                .replaceAll("/", "%2F")
                .replaceAll("%20", "-"));
          this.setState({ mailValue: mailValue });
          //console.log("categories2", categories)
          let products = await getRelatedProducts(productid, categories);

          //console.log("products",products)

          let respCat = await buildProductsSlider(
            products,
            null,
            this.manageCart,
            this.state.prodDefConfig,
            "products"
          );

          //console.log("customTag", cstyle)

          if (customTag) {
            if (cstyle.top !== "initial") {
              estyle["top"] = 42;
            }
          }

          this.setState({
            ...this.state,
            cardConfig: respCat.cardConfig,
            cardData: respCat.cardProducts,
            estyle: estyle,
          });
        }
      }
    } catch (e) {
      console.log(">>>>>>>", e);
      this.setState({ isLoading: false });
    }
  };

  selectOption = async (event) => {
    try {
      var values;
      if (event.target.name) {
        values = event.target.name.split("__");
      } else {
        values = event.target.parentNode.name.split("__");
      }

      var optionid = values[0];
      var optionValue = values[1];

      //console.log("values",values)
      var resp = await selectOption(
        optionid,
        optionValue,
        this.state.product,
        this.state.selectedPrice,
        this.state.selectedSku,
        this.state.quantityProduct,
        this.state.selectedImage,
        this.state.optionsArr
      );
      if (resp.success) {
        var product = resp.product;
        if (resp.isValid) {
          if (resp.changeImage) {
            var newImagesZoom = [];
            var newImages = [];

            for (let i = 0; i < product.images.length; i++) {
              let key = product.images[i];
              if (i < 4) {
                newImages.push({
                  image: key.image,
                  version: "both",
                  imagealt: key.imagealt,
                  onclick: this.toggle(1),
                });
              }
              newImagesZoom.push({
                image: key.image,
                version: "both",
                imagealt: key.imagealt,
                width: "529px",
                height: "529px",
                scale: 2,
              });
            }
            product["imagesZoom"] = newImagesZoom;
            product["images"] = newImages;

            this.setState({ isLoadingSlider: true });

            setTimeout(
              function () {
                this.setState({
                  isLoadingSlider: false,
                  sliderConfig: resp.sliderConfig,
                });
              }.bind(this),
              1
            );
          }

          this.setState({
            product: product,
            isValid: resp.isValid,
            selectedSku: product.sku,
            selectedImage: product.image,
            selectedPrice: product.price,
            optionsselected: product.optionsselected,
          });

          /*
					var _selectedPrice = this.state.selectedPrice 
					this.setState({product      : product,
												 isValid      : resp.isValid, 
												 selectedSku  : product.selectedSku, 
												 selectedImage: product.path, 
												 selectedPrice: (product.selectedPrice?product.selectedPrice: _selectedPrice) });
												 */
        }
      }
      //console.log("product",this.state)
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
    }
  };

  manageCart = async (event) => {
    try {
      this.setState({ isLoading2: true });
      var resp = await manageCart(
        event,
        this.state.cardData,
        this.manageCart,
        toast
      );

      if (resp.success) {
        if (!resp.redirect) {
          this.setState({
            ...this.state,
            isLoading: false,
            //cardData: resp._cardData
          });
          if (resp.dataCart) {
            this.props.updateCart(resp.dataCart);
          } else {
            if (resp.isEvent) {
              this.props.addQtyEvent(resp.eventQty);
            }
          }
        } else {
          this.setState({
            ...this.state,
            isLoading: false,
            selectedProductID: resp.selectedProductID,
            selectedPath: resp.selectedPath,
            selectedCategory: resp.selectedCategory,
            redirectProductDetails: true,
          });

          await this.setProduct();
        }
      } else {
        this.setState({ ...this.state, isLoading: false });
        console.log(">>>>>>>", resp.message);
      }

      this.setState({ isLoading2: false });
    } catch (Excep) {
      this.setState({ isLoading2: false });
      console.log(">>>>>>>", Excep);
    }
  };

  renderData = (_value, _pageData) => {
    try {
      for (let r in _pageData) {
        this.setState({
          [_value + "RENDEROBJ" + _pageData[r].ObjectName]: _pageData[r],
          attrib_custom_landing: true,
        });
      }
      //console.log("-final-", this.state)
    } catch (err) {
      console.log(">>>>>>>>>>>", err);
    }
  };

  decreaseQuantity = (event) => {
    var product = this.state.product;
    var quantitySelected = product.quantitySelected;
    if (product.quantitySelected != null) {
      if (quantitySelected > product.minQuantity) {
        quantitySelected = quantitySelected - this.state.product.decimalstep;
        product["quantitySelected"] = quantitySelected;

        var isValid = true;
        var features = this.state.product.features;
        for (let f in features) {
          if (
            !features[f].selectedOption ||
            features[f].selectedOption == null
          ) {
            isValid = false;
          }
        }
        if (isValid) {
          product = validCombo(product, quantitySelected);
        }

        this.setState({ product: product });
      }
    } else {
      quantitySelected = product["minQuantity"];
      product["quantitySelected"] = quantitySelected;

      let isValid = true;
      let features = this.state.product.features;
      for (let f in features) {
        if (!features[f].selectedOption || features[f].selectedOption == null) {
          isValid = false;
        }
      }
      if (isValid) {
        product = validCombo(product, quantitySelected);
      }

      this.setState({ product: product });
    }
  };

  increaseQuantity = (event) => {
    var product = this.state.product;
    var quantitySelected = product.quantitySelected
      ? product.quantitySelected
      : 0;

    if (quantitySelected < this.state.product.maxQuantity) {
      quantitySelected = quantitySelected + this.state.product.decimalstep;
      product["quantitySelected"] = quantitySelected;

      var isValid = true;
      var features = this.state.product.features;
      for (let f in features) {
        if (!features[f].selectedOption || features[f].selectedOption == null) {
          isValid = false;
        }
      }
      if (isValid) {
        product = validCombo(product, quantitySelected);
      }

      this.setState({ product: product });
    } else {
      showError(toast, getDefValues().limitedExceeded);
    }
  };

  setQuantity = (event) => {
    var product = this.state.product;
    var quantitySelected = event.target.value;
    let val = validateNumber(quantitySelected);
    if (val) {
      //if (quantitySelected >0 && quantitySelected <= this.state.product.maxQuantity ){
      product["quantitySelected"] = parseInt(quantitySelected);

      var isValid = true;
      var features = this.state.product.features;
      for (let f in features) {
        if (!features[f].selectedOption || features[f].selectedOption == null) {
          isValid = false;
        }
      }
      if (isValid) {
        product = validCombo(product, quantitySelected);
      }

      this.setState({ product: product });
      /*}else{
				showError (toast, getDefValues().limitedExceeded );
			}*/
    } else {
      product["quantitySelected"] = "1";
      this.setState({ product: product });
      //showError (toast, getDefValues().errorNumberLabel );
    }
  };

  shareProduct = (event) => {
    try {
      var socialNetwork;
      if (event.target.name) {
        socialNetwork = event.target.name;
      } else {
        socialNetwork = event.target.parentNode.name;
      }
      var url = window.location.pathname;
      var title = this.state.product.title;

      shareProduct(
        socialNetwork,
        url,
        title,
        500,
        350,
        this.state.product.description
      );
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
    }
  };

  addWishlist = async (event) => {
    try {
      this.setState({ isLoading: true });

      await addWishlist(this.state.product.productid, toast);
      var wishlist = await getWishList();
      var wishButton =
        wishlist.indexOf(parseInt(this.state.product.productid)) >= 0
          ? "btn-"
          : "btn-outline-";

      this.setState({ isLoading: false, wishButton: wishButton });
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };

  addShopCart = async (event) => {
    try {
      var product = this.state.product;
      var features = product["features"];
      var optionsselected = [];
      var isValid = true;

      for (let f in features) {
        let pos = find(
          this.state.optionsselected,
          features[f].optionid,
          "optionid"
        );
        if (pos < 0) {
          isValid = false;
          features[f]["showAlert"] = true;
        } else {
          features[f]["showAlert"] = false;
          var selectedOption = this.state.optionsselected[pos].optionvalue;
          var optionLabel = features[f].optionLabel;
          optionsselected.push({
            optionid: features[f].optionid,
            optionLabel: optionLabel,
            optionvalue: selectedOption,
          });
        }
      }

      if (product.quantitySelected == null) {
        isValid = false;
        showError(toast, getDefValues().selectQuantityLabel);
      }

      if (!isValid) {
        product["features"] = features;
        this.setState({ product: product });
      } else {
        if (
          this.state.product.quantitySelected > this.state.product.maxQuantity
        ) {
          showError(toast, getDefValues().limitedExceeded);
        } else {
          this.setState({ isLoading2: true });
          let product = {
            productsku: this.state.selectedSku,

            productid: this.state.product.productid,
            label: this.state.product.title,

            categoryid: this.state.product.categoryid,
            category: formatPathName(this.state.product.category),
            productpath: formatPathName(
              this.state.product.title + "-" + this.state.product.productid
            ),

            path: this.state.selectedImage,
            priceold: this.state.selectedPrice,
            maxQuantity: this.state.product.maxQuantity,
            options: this.state.product.features,
            quantities: this.state.product.quantities,
            features: this.state.product.features,
            sku: this.state.selectedSku,
            optionsselected: optionsselected,
            skus: this.state.selectedSKUS,
          };

          if (this.state.action) {
            if (this.state.action === "addEventCart") {
              product["eventid"] = this.state.id;
            }
          }

          var data = await addShopCart(
            product,
            this.state.product.quantitySelected,
            toast
          );
          if (data) {
            this.props.updateCart(data);
          }

          this.setState({ ...this.state, isLoading2: false });
        }
      }
    } catch (Excep) {
      this.setState({ isLoading2: false });
      console.log(">>>>>>>", Excep);
    }
  };

  addEventList = async (event) => {
    try {
      var product = this.state.product;
      var features = product["features"];
      var optionsselected = [];
      var isValid = true;

      for (let f in features) {
        let pos = find(
          this.state.optionsselected,
          features[f].optionid,
          "optionid"
        );
        if (pos < 0) {
          isValid = false;
          features[f]["showAlert"] = true;
        } else {
          features[f]["showAlert"] = false;
          var selectedOption = this.state.optionsselected[pos].optionvalue;
          var optionLabel = features[f].optionLabel;
          optionsselected.push({
            optionid: features[f].optionid,
            optionLabel: optionLabel,
            optionvalue: selectedOption,
          });
        }
      }

      if (product.quantitySelected == null) {
        isValid = false;
        showError(toast, getDefValues().selectQuantityLabel);
      }

      if (!isValid) {
        product["features"] = features;
        this.setState({ product: product });
      } else {
        if (
          this.state.product.quantitySelected > this.state.product.maxQuantity
        ) {
          showError(toast, getDefValues().limitedExceeded);
        } else {
          this.setState({ isLoading2: true });
          let formData = {
            sku: this.state.selectedSku,
            productid: this.state.product.productid,
            eventid: this.state.id,
            quantity: this.state.product.quantitySelected,
          };
          let resp = await callApi("frontend", "setProductEvent", formData);
          if (resp.success) {
            showMessage(
              toast,
              getDefValues().successfullyAddedToEventListLabel
            );
            this.props.addQtyEvent(this.state.product.quantitySelected);
          } else {
            showError(toast, getDefValues().errorAddedToEventListLabel);
          }
          this.setState({ ...this.state, isLoading2: false });
        }
      }
    } catch (Excep) {
      this.setState({ isLoading2: false });
      console.log(">>>>>>>", Excep);
    }
  };

  toggleProdDesc = (event) => {
    this.setState({ showproddesc: this.state.showproddesc ? false : true });
  };

  toggleProdInfo = (event) => {
    this.setState({ showprodinfo: this.state.showprodinfo ? false : true });
  };

  toggleCustomInfo = (event) => {
    var prodcustominfoArr = this.state.prodcustominfoArr;
    let pos = find(prodcustominfoArr, event, "value");
    //console.log(prodcustominfoArr[pos])
    prodcustominfoArr[pos].show = prodcustominfoArr[pos].show ? false : true;

    this.setState({ prodcustominfoArr: prodcustominfoArr });
  };

  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  render() {
    if (this.state.noProduct) {
      return "";
    } else {
      return this.props.showSearcher ? (
        ""
      ) : (
        <>
          <div className="application">
            <Helmet>
              <meta charSet="utf-8" />
              <title>{this.state.MetaTags.title}</title>
              <link rel="canonical" href={this.state.MetaTags.path} />
              <meta name="title" content={this.state.MetaTags.title} />
              <meta
                name="description"
                content={this.state.MetaTags.description}
              />
            </Helmet>
          </div>

          <PageLoading
            isLoading={this.state.isLoading}
            MetaTags={this.state.MetaTags}
          />
          <PageLoading isLoading={this.state.isLoading2} />
          <div className="mt-10 mb-10" onClick={this.props.hideCart}>
            {this.state.redirectProductDetails ? (
              <Redirect
                to={{
                  pathname:
                    (this.state.selectedCategory
                      ? "/" + this.state.selectedCategory + "/"
                      : "/productDetails/") +
                    (this.state.selectedPath
                      ? this.state.selectedPath
                      : "show"),
                }}
              />
            ) : (
              ""
            )}

            <div className="container container-page">
              {!this.state.isLoading ? (
                this.state.productNotFounded ? (
                  <NotFounded messages={this.state.messages} />
                ) : (
                  <>
                    <MDBModal
                      isOpen={this.state.modal1}
                      toggle={this.toggle(1)}
                      fade
                      className="zoom-modal"
                    >
                      <MDBModalHeader toggle={this.toggle(1)}></MDBModalHeader>
                      <MDBModalBody style={{ alignItems: "start" }}>
                        <SliderImage
                          sliderData={this.state.product.imagesZoom}
                          sliderConfig={this.state.sliderConfigZoom}
                        />
                      </MDBModalBody>
                    </MDBModal>

                    <div className="categoryRoot mt-4 mb-2">
                      {this.state.orgName + " >- "}
                      {this.state.redirectToEvent ? (
                        <a
                          href={"/customer/event/home"}
                          className="transparent-button"
                          id={"event"}
                          name={"event"}
                        >
                          {" "}
                          {getDefValues().eventListTitleLabel + " > "}
                        </a>
                      ) : (
                        this.state.breadcrumbs.map((cat) =>
                          cat.link ? (
                            <a
                              href={cat.link}
                              className="transparent-button"
                              id={cat.id}
                              name={"cat" + cat.id}
                            >
                              {" "}
                              {cat.label + " > "}
                            </a>
                          ) : (
                            <>{cat.label.toLowerCase() + " > "}</>
                          )
                        )
                      )}
                      <span className="text-bold">
                        {this.state.productTitle.toLowerCase()}
                      </span>
                    </div>

                    <div className="row ">
                      <div className="col-lg-12">
                        <div className="product-detail accordion-detail">
                          <div className="row mb-10">
                            <div className="col-lg-5 col-12">
                              <div className="detail-gallery">
                                {this.state.product.tag === "NEW" ? (
                                  <span
                                    className={
                                      this.state.product.tag === "NEW"
                                        ? "tag new-tag"
                                        : "tag discount-tag"
                                    }
                                  >
                                    {this.state.product.tag === "NEW"
                                      ? getDefValues().newLabel
                                      : this.state.product.tag}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {this.state.skusinDiscount.indexOf(
                                  this.state.selectedSku + ""
                                ) >= 0 ||
                                this.state.skusinCombo.indexOf(
                                  this.state.selectedSku + ""
                                ) >= 0 ? (
                                  <>
                                    {this.state.product.tag !== "" ? (
                                      <span
                                        className={
                                          this.state.product.tag === "NEW"
                                            ? "tag new-tag"
                                            : "tag discount-tag"
                                        }
                                      >
                                        {this.state.product.tag === "NEW"
                                          ? getDefValues().newLabel
                                          : this.state.product.tag}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}

                                {this.state.customTag ? (
                                  this.state.cstyle.imagepath ? (
                                    <div
                                      style={this.state.cstyle}
                                      className={"custom-product-tag"}
                                    >
                                      <img
                                        src={getImagePath(
                                          this.state.customTag.style.imagepath
                                        )}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      style={this.state.cstyle}
                                      className={"custom-product-tag"}
                                    >
                                      {this.state.customTag.label}
                                    </div>
                                  )
                                ) : (
                                  ""
                                )}

                                {this.state.suggestedQty > 0 && (
                                  <div
                                    style={this.state.estyle}
                                    className={"product-event-image"}
                                  >
                                    {getDefValues().quantityRequestedLabel.toUpperCase() +
                                      ": "}
                                    <span className="text-bold h6">
                                      {" "}
                                      {this.state.suggestedQty}{" "}
                                    </span>
                                  </div>
                                )}

                                <div className="product-image-slider">
                                  {!this.state.isLoadingSlider ? (
                                    <SliderImage
                                      sliderData={this.state.product.images}
                                      sliderConfig={this.state.sliderConfig}
                                    />
                                  ) : (
                                    <SliderImage
                                      sliderData={this.state.tempimages}
                                      sliderConfig={this.state.sliderConfig}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-7 col-12">
                              <div className="product-info">
                                <p>
                                  <span className="sku">
                                    {" "}
                                    {(this.state.product.packname
                                      ? getDefValues().packRefLabel + ": "
                                      : "SKU: ") + this.state.selectedSku}{" "}
                                  </span>
                                </p>
                                <h1 className="title-detail">
                                  {this.state.product.title}
                                </h1>
                                {/*
													<p>
														<span className="title-description">
															{this.state.product.description}
														</span>
													</p>
												 */}
                                <div className="price-info">
                                  {this.state.product.discount > 0 ? (
                                    <>
                                      {this.state.skusinDiscount.indexOf(
                                        this.state.selectedSku + ""
                                      ) >= 0 ||
                                      this.state.skusinCombo.indexOf(
                                        this.state.selectedSku + ""
                                      ) >= 0 ? (
                                        <>
                                          <span className="text-brand">
                                            {this.state.currency}{" "}
                                            {toFixed(
                                              this.state.product.newprice
                                            )}
                                          </span>
                                          <span className="old-price  ml-15">
                                            {this.state.currency}{" "}
                                            {toFixed(this.state.selectedPrice)}
                                          </span>
                                        </>
                                      ) : (
                                        <span className="price">
                                          {this.state.currency}{" "}
                                          {toFixed(this.state.selectedPrice)}
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    <span className="price">
                                      {this.state.currency}{" "}
                                      {toFixed(this.state.selectedPrice)}
                                    </span>
                                  )}
                                </div>

                                {this.state.quantityProduct.length === 1
                                  ? ""
                                  : this.state.product.features.map(
                                      (feat, i) => (
                                        <>
                                          {feat.optionvalues.length > 1 ? (
                                            <div
                                              key={"feat_" + i}
                                              className="attr-detail attr-color mb-15"
                                            >
                                              <strong className="mr-10">
                                                {feat.optionLabel}
                                              </strong>

                                              <ul className="list-filter color-filter">
                                                {feat.optionvalues.map(
                                                  (img, i) => (
                                                    <li
                                                      key={"value_" + i}
                                                      className={
                                                        "mr-1" +
                                                        (feat.selectedOption ===
                                                        img.value
                                                          ? " list-filter-li-active"
                                                          : "")
                                                      }
                                                    >
                                                      {img.image !== "" &&
                                                      img.image !== "NODEF" ? (
                                                        <button
                                                          className="no-button"
                                                          name={
                                                            feat.optionid +
                                                            "__" +
                                                            img.value
                                                          }
                                                          id={
                                                            feat.optionid +
                                                            "__" +
                                                            img.value
                                                          }
                                                          onClick={
                                                            !img.banned
                                                              ? this
                                                                  .selectOption
                                                              : null
                                                          }
                                                        >
                                                          <img
                                                            style={img.style}
                                                            src={getImagePath(
                                                              img.image
                                                            )}
                                                            alt={
                                                              feat.optionid +
                                                              "__" +
                                                              img.value
                                                            }
                                                            name={
                                                              feat.optionid +
                                                              "__" +
                                                              img.value
                                                            }
                                                            id={
                                                              feat.optionid +
                                                              "__" +
                                                              img.value
                                                            }
                                                          />
                                                          {this.state
                                                            .optionsselected
                                                            ? this.state.optionsselected.map(
                                                                (selec, i) =>
                                                                  selec.optionid ==
                                                                    feat.optionid &&
                                                                  selec.optionvalue ==
                                                                    img.value ? (
                                                                    <div className="active-div"></div>
                                                                  ) : (
                                                                    ""
                                                                  )
                                                              )
                                                            : ""}
                                                          {img.banned ? (
                                                            <div className="ban-div">
                                                              {" "}
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </button>
                                                      ) : (
                                                        <button
                                                          className={
                                                            (this.state
                                                              .optionsselected
                                                              ? this.state.optionsselected.map(
                                                                  (selec, i) =>
                                                                    selec.optionid ==
                                                                      feat.optionid &&
                                                                    selec.optionvalue ==
                                                                      img.value
                                                                      ? " active-option "
                                                                      : " "
                                                                )
                                                              : "") +
                                                            " option-button " +
                                                            (img.banned
                                                              ? " ban-option-button "
                                                              : "")
                                                          }
                                                          key={
                                                            this.state
                                                              .productid +
                                                            "_" +
                                                            feat.optionid
                                                          }
                                                          onClick={
                                                            !img.banned
                                                              ? this
                                                                  .selectOption
                                                              : null
                                                          }
                                                          src={img.image}
                                                          name={
                                                            feat.optionid +
                                                            "__" +
                                                            img.value
                                                          }
                                                          id={
                                                            feat.optionid +
                                                            "__" +
                                                            img.value
                                                          }
                                                        >
                                                          {img.banned ? (
                                                            <div className="ban-option">
                                                              {" "}
                                                              <span>
                                                                {" "}
                                                                {img.label}{" "}
                                                              </span>{" "}
                                                            </div>
                                                          ) : (
                                                            <span>
                                                              {" "}
                                                              {img.label}{" "}
                                                            </span>
                                                          )}
                                                        </button>
                                                      )}
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                              {feat.showAlert ? (
                                                <span className="alert-option">
                                                  {" "}
                                                  {getDefValues()
                                                    .messageCompleteOption +
                                                    " " +
                                                    feat.optionLabel}{" "}
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )
                                    )}
                                <div className="bt-1 border-color-1 mt-3 mb-3"></div>
                                <div className="detail-extralink-container">
                                  <div className="detail-extralink">
                                    <div className="detail-qty">
                                      <button
                                        onClick={this.decreaseQuantity}
                                        className="qty-down"
                                      >
                                        <i className="fas fa-minus"></i>
                                      </button>
                                      <input
                                        type="text"
                                        className="qty-val"
                                        name="quanqity"
                                        value={
                                          this.state.product.quantitySelected
                                        }
                                        onChange={this.setQuantity}
                                      />
                                      <button
                                        onClick={this.increaseQuantity}
                                        className="qty-up"
                                      >
                                        <i className="fas fa-plus"></i>
                                      </button>
                                    </div>

                                    {!this.state.action ? (
                                      <button
                                        onClick={this.addShopCart}
                                        className="btn btn-product-info btn-primary ml-1 mr-1"
                                      >
                                        {this.state.btnaddbutton}
                                      </button>
                                    ) : (
                                      <>
                                        {this.state.action ===
                                        "addEventList" ? (
                                          <button
                                            onClick={this.addEventList}
                                            className="btn btn-product-info btn-secondary ml-1 mr-1"
                                          >
                                            {this.state.btnaddListbutton}
                                          </button>
                                        ) : (
                                          ""
                                        )}

                                        {this.state.action ===
                                        "addEventCart" ? (
                                          <button
                                            onClick={this.addShopCart}
                                            className="btn btn-product-info btn-secondary ml-1 mr-1"
                                          >
                                            {this.state.btnaddbutton}
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                    {!this.state.hideWishlistOption ? (
                                      <button
                                        onClick={this.addWishlist}
                                        className={
                                          "btn btn-product-info " +
                                          this.state.wishButton +
                                          this.state.btncolor
                                        }
                                      >
                                        <i className="fi-rs-heart"></i>
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                {this.state.product.skusInCombo.length > 0 ? (
                                  <div className="card-combo font-md mb-30 mt-3">
                                    <div className="row mt-3">
                                      <div className="col-12">
                                        {getDefValues().comboSkusMessageLabel}
                                      </div>
                                    </div>
                                    {this.state.product.skusInCombo.map(
                                      (opt) => (
                                        <div className="row mt-2 ml-2">
                                          <div className="col-12">
                                            {opt.options}{" "}
                                            <span className="font-xs">
                                              {" "}
                                              {" (SKU: " + opt.sku + ")"}{" "}
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}

                                {this.state.product.skusInDiscount.length >
                                0 ? (
                                  <div className="card-combo font-md mb-30 mt-3">
                                    <div className="row mt-3">
                                      <div className="col-12">
                                        {
                                          getDefValues()
                                            .discountSkusMessageLabel
                                        }
                                      </div>
                                    </div>
                                    {this.state.product.skusInDiscount.map(
                                      (opt) => (
                                        <div className="row mt-2 ml-2">
                                          <div className="col-12">
                                            {opt.options}{" "}
                                            <span className="font-xs">
                                              {" "}
                                              {" (SKU: " + opt.sku + ")"}{" "}
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="product_sort_info font-xs mb-30 mt-3">
                                  <div className="row align-botton mt-2 ml-1 mr-1">
                                    <div
                                      className="col-10"
                                      style={{ cursor: "pointer" }}
                                      onClick={this.toggleProdDesc}
                                    >
                                      <span className="h6-responsive">
                                        {" "}
                                        {this.state.proddecriptionlabel}
                                      </span>
                                    </div>
                                    <div
                                      style={{ cursor: "pointer" }}
                                      className="col-2"
                                      onClick={this.toggleProdDesc}
                                    >
                                      <span
                                        className="h6-responsive flex items-right"
                                        style={{ cursor: "pointer" }}
                                        onClick={this.toggleProdDesc}
                                      >
                                        {" "}
                                        {!this.state.showproddesc
                                          ? "+"
                                          : "-"}{" "}
                                      </span>
                                    </div>
                                  </div>
                                  {this.state.showproddesc ? (
                                    <>
                                      <div className="row align-botton ml-1">
                                        <div className="col-12">
                                          {this.state.product.descriptions.map(
                                            (desc) => (
                                              <p
                                                className={
                                                  !this.state.showproddesc
                                                    ? "showproduct"
                                                    : "hideproduct"
                                                }
                                              >
                                                {desc}
                                              </p>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="product_sort_info font-xs mb-30">
                                  <div className="row align-botton mt-3 ml-1 mr-1">
                                    <div
                                      className="col-10"
                                      style={{ cursor: "pointer" }}
                                      onClick={this.toggleProdInfo}
                                    >
                                      <span className="h6-responsive">
                                        {" "}
                                        {this.state.prodaditionalinfo}
                                      </span>
                                    </div>
                                    <div
                                      style={{ cursor: "pointer" }}
                                      className="col-2"
                                      onClick={this.toggleProdInfo}
                                    >
                                      <span
                                        className="h6-responsive flex items-right"
                                        style={{ cursor: "pointer" }}
                                        onClick={this.toggleProdInfo}
                                      >
                                        {" "}
                                        {!this.state.showprodinfo
                                          ? "+"
                                          : "-"}{" "}
                                      </span>
                                    </div>
                                  </div>
                                  {this.state.showprodinfo ? (
                                    <>
                                      {this.state.product.productAttributes.map(
                                        (attr, i) => (
                                          <div className="row align-botton ml-3 mt-3">
                                            {getDefValues().filesNames.indexOf(
                                              attr.type
                                            ) >= 0 ? (
                                              <>
                                                <div className="col-12">
                                                  <p>
                                                    {" "}
                                                    <b>
                                                      {attr.label.toUpperCase() +
                                                        ":"}
                                                    </b>{" "}
                                                  </p>
                                                </div>
                                                <div className="col-12">
                                                  <p>
                                                    <a
                                                      className="attrib-link"
                                                      href={getFilePath(
                                                        attr.value
                                                      )}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      {" "}
                                                      {
                                                        getDefValues()
                                                          .downloadHereLabel
                                                      }{" "}
                                                    </a>
                                                  </p>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div className="col-12">
                                                  <p>
                                                    {" "}
                                                    <b>
                                                      {attr.label.toUpperCase() +
                                                        ":"}
                                                    </b>{" "}
                                                  </p>
                                                </div>
                                                <div className="col-12">
                                                  {attr.atributevalues.map(
                                                    (a) => (
                                                      <p>{a.toUpperCase()}</p>
                                                    )
                                                  )}
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {this.state.attrib_custom_landing ? (
                                  <>
                                    {this.state.prodcustominfoArr.map((c) => (
                                      <div className="product_sort_info font-xs mb-30">
                                        <div className="row align-botton mt-3 ml-1 mr-1">
                                          <div
                                            className="col-10"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.toggleCustomInfo(c.value)
                                            }
                                          >
                                            <span className="h6-responsive">
                                              {" "}
                                              {c.label}
                                            </span>
                                          </div>
                                          <div
                                            style={{ cursor: "pointer" }}
                                            className="col-2"
                                            onClick={() =>
                                              this.toggleCustomInfo(c.value)
                                            }
                                          >
                                            <span
                                              className="h6-responsive flex items-right"
                                              style={{ cursor: "pointer" }}
                                            >
                                              {" "}
                                              {!c.show ? "+" : "-"}{" "}
                                            </span>
                                          </div>
                                        </div>
                                        {c.show ? (
                                          <div className="row align-botton ml-2">
                                            <div className="col-12">
                                              {Object.keys(this.state).map(
                                                (obj) => (
                                                  <>
                                                    {obj.startsWith(
                                                      c.value + "RENDEROBJ"
                                                    ) ? (
                                                      <>
                                                        {this.state[obj]
                                                          .type === "TEXT" ? (
                                                          <Text
                                                            name={
                                                              this.state[obj]
                                                                .ObjectName
                                                            }
                                                            textData={
                                                              this.state[obj]
                                                                .data
                                                            }
                                                            textConfig={
                                                              this.state[obj]
                                                                .config
                                                            }
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                        {this.state[obj]
                                                          .type === "IMAGE" ? (
                                                          <Image
                                                            name={
                                                              this.state[obj]
                                                                .ObjectName
                                                            }
                                                            imageData={
                                                              this.state[obj]
                                                                .data
                                                            }
                                                            imageConfig={
                                                              this.state[obj]
                                                                .config
                                                            }
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                        {this.state[obj]
                                                          .type ===
                                                        "CATEGORYBLOG" ? (
                                                          <CategoryBlog
                                                            name={
                                                              this.state[obj]
                                                                .ObjectName
                                                            }
                                                            categoryBlogData={
                                                              this.state[obj]
                                                                .data
                                                            }
                                                            categoryBlogContent={
                                                              this.state[obj]
                                                                .content
                                                            }
                                                            categoryBlogConfig={
                                                              this.state[obj]
                                                                .config
                                                            }
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                        {this.state[obj]
                                                          .type === "FORM" ? (
                                                          <Form
                                                            name={
                                                              this.state[obj]
                                                                .ObjectName
                                                            }
                                                            container={
                                                              this.state[obj]
                                                                .ObjectName
                                                            }
                                                            formData={
                                                              this.state[obj]
                                                                .data
                                                            }
                                                            changeHandler={
                                                              this.changeHandler
                                                            }
                                                            manageButton={
                                                              this.manageButton
                                                            }
                                                            formConfig={
                                                              this.state[obj]
                                                                .config
                                                            }
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                        {this.state[obj]
                                                          .type ===
                                                        "SLIDERPRODUCTS" ? (
                                                          <Slider
                                                            name={
                                                              this.state[obj]
                                                                .ObjectName
                                                            }
                                                            sliderData={
                                                              this.state[obj]
                                                                .data
                                                            }
                                                            sliderConfig={
                                                              this.state[obj]
                                                                .config
                                                            }
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                        {this.state[obj]
                                                          .type ===
                                                        "CARDCONTAINER" ? (
                                                          <Card
                                                            name={
                                                              this.state[obj]
                                                                .ObjectName
                                                            }
                                                            cardData={
                                                              this.state[obj]
                                                                .data
                                                            }
                                                            cardConfig={
                                                              this.state[obj]
                                                                .config
                                                            }
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                        {this.state[obj]
                                                          .type ===
                                                        "SLIDERIMAGE" ? (
                                                          <SliderImage
                                                            sliderData={
                                                              this.state[obj]
                                                                .data
                                                            }
                                                            sliderConfig={
                                                              this.state[obj]
                                                                .config
                                                            }
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                        {this.state[obj]
                                                          .type === "MODAL" ? (
                                                          <Modal
                                                            name={
                                                              this.state[obj]
                                                                .ObjectName
                                                            }
                                                            modalData={
                                                              this.state[obj]
                                                                .data
                                                            }
                                                            modalConfig={
                                                              this.state[obj]
                                                                .config
                                                            }
                                                            toggle={
                                                              this.toggleModal
                                                            }
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                        {this.state[obj]
                                                          .type === "SLIDER" ||
                                                        this.state[obj].type ===
                                                          "SLIDERCONTAINER" ? (
                                                          <Slider
                                                            name={
                                                              this.state[obj]
                                                                .ObjectName
                                                            }
                                                            sliderData={
                                                              this.state[obj]
                                                                .data
                                                            }
                                                            sliderConfig={
                                                              this.state[obj]
                                                                .config
                                                            }
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                        {this.state[obj]
                                                          .type === "BUTTON" ? (
                                                          <Button
                                                            name={
                                                              this.state[obj]
                                                                .ObjectName
                                                            }
                                                            buttonData={
                                                              this.state[obj]
                                                                .data
                                                            }
                                                            buttonConfig={
                                                              this.state[obj]
                                                                .config
                                                            }
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  ""
                                )}
                                <div className="row align-botton mt-2 ml-1 mr-1">
                                  <div
                                    className="col-10"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="social-icons single-share">
                                      <ul className="d-inline-block">
                                        <li className="mr-5">
                                          <h6>{this.state.prodShare}:</h6>
                                        </li>

                                        <li className="social-facebook">
                                          <button
                                            name="facebook"
                                            onClick={this.shareProduct}
                                          >
                                            <img src={facebook} alt="" />
                                          </button>
                                        </li>
                                        <li className="social-whatsapp">
                                          <button
                                            name="whatsapp"
                                            onClick={this.shareProduct}
                                          >
                                            <img src={whatsapp} alt="" />
                                          </button>
                                        </li>
                                        <li className="social-twitter">
                                          <button
                                            name="twitter"
                                            onClick={this.shareProduct}
                                          >
                                            <img src={twitter} alt="" />
                                          </button>
                                        </li>
                                        <li
                                          className="social-email"
                                          style={{ marginLeft: 4 }}
                                        >
                                          <a
                                            id="mailto-link"
                                            href={this.state.mailValue}
                                          >
                                            <img src={email} alt="" />
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              ) : (
                ""
              )}

              {this.state.cardData.length > 0 ? (
                <>
                  <div className="row row mt-50 mb-50">
                    <div className="col-md-12 col-lg-12">
                      <span className="h3-responsive">
                        {getDefValues().relatedProductsLabel}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <Slider
                        ObjectName={"SLIDERPROD"}
                        sliderData={this.state.cardData}
                        sliderConfig={this.state.prodDefConfig}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
		  
            <div style={{minHeight:50}} id="exponea-carousel-nuestros-disenadores-te-sugieren-pdp"></div>
            <div style={{minHeight:50}} id="exponea-carousel-recientemente-vistos-pdp"></div>
		  
          </div>
        </>
      );
    }
  }
}
export default page;
